<template>
    <ConfirmDialog group="dialog" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-fluid">
                <h4><strong>{{$t('Inquiry.View.Heading')}}</strong></h4>

                <!-- <h4><strong>Comments</strong></h4> -->
                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"><img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6UlEQVR4nNWXS2wOURTHf62WUmXhlZRWaUNXNgSxQ1Ui7UIEkUhZecRjVxUs8Gm86tFUSIiVjSYSj51HRAWfUiQk4pUgEWFDqkL0K5/c5D/ydTr3zszXSvgnk8zcc885d8458z9n4D9FAVABzAJmAOOB/L/ttAzYBSSBbiDtu3qAW0CDDjdgmAi0Aj+BZ8A+YD5QCRTqMvdVwH7guQ5zGijpr/O1wHfgPjAvhp45YId0V2fjOBc4qVBvAHIs+wYBeRaZ0dkEpIBD2hsZx4FPwFyLfDHQrrT8Ah4Ayx3R+Aw0RXW+Rm9uc75Djs0hFwE1QIvyvtdxCGOzLsx5OfBVYQ/CbL3xsgBZrWS2g28GvoUV5hkVnC3nprKvOvTPA2cddWVSdcqmPFlhdFX7bSDhkNcDjx3yKhWliXQfbNd37sIV4IRDbnjgToiNF8CWIEGHSMYFo/gOGBkgGwa8AnaH2DgAtPkX84EfCpELI4A3wGVgXMb6KOAC8F73LixQGnrxR6X4fArhmAo8BbqA68A14IveflpE/bQa2B/M1GIR0WAithQ4DBwBVgBDIuoWydf0zMU5MQ4wHFgCNAPndDVrrTDbA1Ro0YTHhsHAVtGqCflF4BhwFLgEdErWoL02eOku9hvvcRThGOAm8AFYZ3FQIBr/qL2jLbaqRct9mlhSn4gfQ4F7YrFehWNBsfa2S9ePg8CNIMWdIgk/TH5fA2OJDhOxtypQP17aiKhMaTCdy8Mk8YPpeHFRK11jw0/FhvYD0Qo8VOPwWq+L28PwBNime2PzkQYd5/zXBWzUsymmPf04QGNGvr12PCFMab3CVC3aDR0iHFglGwtlc2VUxRZ9191Z5t9DjWx0xhnJ0ADZJMJozKiJOMiVblq2Yg2lHuqUt6RGLduklIkcDTV3pRs57DaUaIxKqQsmlNPSjB+TUq0ltCelag8tuDgoF4G0yYH/1yyliq93fecDhTxRrvkxNV3N3Nt+UP5t/AZoVK5P6jp21AAAAABJRU5ErkJggg==">
                        <p id="nameis"><b>{{
                            name
                        }}.</b></p>
                    </label>
                    <div class="p-col-12 p-md-10">
                        <p><b>{{ FormatDate(createdDate) }}</b></p>
                    </div>
                    <p class="p-col-12 p-mb-2 p-md-2 p-mb-md-0" id="type"><b>{{ userType }}</b></p>
                </div>
                <div class="p-field p-grid">
                    <label for="name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('Inquiry.View.InquiryID')}}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ applicant_id === null ? '--' : applicant_id }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('Inquiry.View.InquiryTitle')}}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ title }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('Inquiry.View.Inquiry')}}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ description }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="title" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('Inquiry.View.Attachment')}}:</label>
                    <div class="p-col-12 p-md-10">
                        <div class="text-red" v-show="render4">{{ $t('validation.invalidFile') }}</div>
                        
                        <img :src="image_path" alt="이미지를 사용할 수 없음" class="product-image" />
                    </div>
                </div>
            </div>
        </div>

        <div class="p-col-12">
            <div class="card p-fluid">
                <h4><strong>{{$t('Inquiry.View.ReplyHeading')}}</strong></h4>
                <div class="p-field p-grid">
                    <div class="p-col-12 p-md-12">
                        <textarea style="width: 97%; margin-left: 0px; margin-top: 14px"
                            @keydown.space="preventLeadingSpace" :autoResize="true" rows="5" cols="30"
                            v-model="replyText" :placeholder="$t('Inquiry.View.DescriptionPlace')" maxlength="1500"></textarea>
                    </div>

                </div>
                <div class="p-grid p-formgrid p-mb-3">
                    <div class="p-col-12 p-mb-2 p-lg-6 p-mb-lg-0 p-field">
                        <label for="subtitle2">
                            {{$t('Inquiry.View.Upload')}}
                            <!-- <span class="img-info">(파일 형식: jpg,jpeg,png )(이미지 너비 924px and 고저 860px 및 크기 1MB )</span> -->
                        </label>
                        <div :class="`${error.file ? 'custom-select-invalid' : 'custom-select'}`">
                            <span v-if="!fileName">{{$t('Inquiry.View.fileupload')}}</span>
                            <span v-else>{{ fileName }}</span>
                            <input type="file" class="select-file" v-on:change="onFileChange" multiple/>
                            <Button :label="$t('Inquiry.View.ChooseFile')" class="SelectBtn n-wrap" />
                        </div>
                        <!-- <div class="text-red">{{ error.file }}</div> -->
                        <div class="text-red" v-show="render1">유효하지 않은 파일 형식</div>
                        <div class="text-red" v-show="render2">최대 파일 크기는 5MB여야 합니다.</div>
                        <div class="text-red" v-show="render3">최대 3개의 파일이 허용됩니다.</div>

                        <!-- <img :src="image_path" width="100px" height="100px" class="product-image" /> -->
                    </div>

                </div>
                <div class="p-d-flex p-jc-between p-ai-center">
                    <div>
                        <Button :label="$t('Inquiry.View.Reply')" class="p-button-outlined p-mr-2 p-mb-2 p-button-sm" id="Save"
                            icon="pi pi-reply" iconPos="left" @click="PostComments()"></Button>
                    </div>
                    <div>
                        <Button :label="$t('Inquiry.View.Back')" icon="pi pi-replay" iconPos="left"
                            class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2"
                            @click="$router.go(-1)"></Button>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import axios from 'axios';
import InquiryService from '../../../service/API/SuperAdmin/InquiryService.js';
import { useRoute } from 'vue-router';
import moment from 'moment';
export default {
    data() {
        return {
            bulletinCategoryDetail: {},
            title: '',
            description: '',
            createdDate: '',
            image: '',
            status: '',
            category_name: '',
            country_name: '',
            image_path: '',
            posted_date: '',
            comments: '',
            free_board_id: '',
            comment: '',
            name: '',
            created_at: '',
            FreeComments: '',
            is_admin_post: '',

            file: '',
            files: '',
            fileName: '',
            product_image: '',
            fileExtension: '',
            filesExtension: '',
            fileExtensions: '',
            filesExtensions: '',
            applicant_id:'',
            replyText:'',

            expose: '',
            render2: false,
            render1: false,
            render3: false,
            // status:'',

            formData: new FormData(),
            error: {},
        };
    },
    created() {
        this.inquiryService = new InquiryService();
    },

    mounted() {
        const route = useRoute();
        console.log(route.params);
        // this.UserComments(this.$route.params.id)

        this.inquiryService.viewInquiry(this.$route.params.id).then((res) => {
            this.applicant_id = res.data.data.inquery[0].applicant_id;
            this.createdDate = res.data.data.inquery[0].createdDate;
            this.description = res.data.data.inquery[0].description;
            this.name = res.data.data.inquery[0].name;
            this.userType = res.data.data.inquery[0].userType;
            this.status = res.data.data.inquery[0].status;
            this.image = res.data.data.inquery[0].image;
            this.images_admin = res.data.data.inquery[0].inquiry_admin_images;
            this.is_admin_post = res.data.data.inquery[0].is_admin_post;
            this.title = res.data.data.inquery[0].title;
            // this.comment = res.data.data[0].comments[0].comment;
            // this.name = res.data.data[0].comments[0].name;
            // this.created_at = res.data.data[0].comments[0].created_at;

            console.log(res);
        });
    },
    methods: {
        FormatDate(value) {
            if (value) {
                // return moment(String(value)).format('YYYY/MM/DD  h:mm:ss');
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },
        preventLeadingSpace(e) {
            // only prevent the keypress if the value is blank
            if (!e.target.value) e.preventDefault();
            // otherwise, if the leading character is a space, remove all leading white-space
            else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, "");
        },
        // onFileChange(e) {
        //     var files = e.target.files || e.dataTransfer.files;
        //     if (!files.length) return;
        //     this.file = files[0];
        //     var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        //     if (!allowedExtensions.exec(this.file.name)) {
        //         this.render1 = true;
        //         return false;
        //     } else {
        //         this.render1 = false;
        //         this.fileName = this.file.name;
        //         this.formData.append('image', files[0]);
        //     }
        //     this.fileExtension = this.fileName.replace(/^.*\./, '');
        //     console.log(this.fileName);
        // },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.file = files[0];
            if (files.length > 3) {
                // alert('3 이미지 선택');
                this.render3 = true;
                return;
            }
            var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!allowedExtensions.exec(this.file.name)) {
                this.render1 = true;
                this.render2 = false;
                this.render3 = false;
            } else {
                let reader = new FileReader
                reader.readAsDataURL(this.file)
                reader.onload = (e) => {
                    let url = e.target.result
                    this.image_url = url
                }
                this.render1 = false;
                if (this.file.size > 5242880) {
                    // alert('파일이 너무 큽니다  ');
                    this.render2 = true;
                    this.render3 = false;
                } else {
                    this.render2 = false;
                    this.fileName = this.file.name;
                    Array.from(files).forEach((element) => {
                        this.formData.append('image', element);
                    });
                    // this.formData.append('image', files[0]);
                    // this.upload(e)
                }
            }
            this.fileExtension = this.fileName.replace(/^.*\./, '');
            // console.log(this.element);
        },

        PostComments() {
            // let vcheckData = {
            //     note: this.note,
            //     // type: this.dropdownValueType == null ? '' : 'something',
            // };
            // const { isInvalid, error } = validateJobSeekerNote(vcheckData);
            // if (isInvalid) {
            //     this.error = error;
            //     console.log(error);
            // } else {
            //     console.log(this.formData);
            this.formData.append('id', this.$route.params.id);
            this.formData.append('replyText', this.replyText);
            // this.formData.append('country_id', this.country_id);
            // this.formData.append('category_id', this.category_id);
            // this.formData.append('expose', this.expose == '1' ? '1' : '0');
            return axios
                .put('/asnhist-dev/api/v1/admin/inquery/reply', this.formData)
                .then(() => {
                   
                    this.$router.push({ name: 'ManagerInquirylist' });
                    setTimeout(() => {
                        this.$toast.add({ severity: 'success', summary: '업데이트 완료', detail: '성공적으로 답변되었습니다.', life: 3000 });
                    }, 500);
                });
            // }
        },
    }
};
</script>

<style scoped>
.p-fluid .p-button {
    width: auto;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
    color: white;
}

.product-image {
    padding-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 150px;
    height: 100px;

}

button#Save {
    margin-left: 13px;
    margin-top: 15px;
}

div#double-text {
    display: flex;
    justify-content: space-between;
}

.custom-select {
    position: relative;
    border: 1px solid #cecece;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
}

.custom-select-invalid {
    position: relative;
    border: 1px solid red;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
}

.select-file {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0%;
    z-index: 5;
    opacity: 0;
}

.SelectBtn {
    max-width: 100px;
}

.custom-select span {
    max-width: 140px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
    color: white;
}

p#type {
    margin-left: 40px;
    margin-top: -15px;
}

p#nameis {
    margin-left: 10px;
    margin-top: -16px;
}
</style>